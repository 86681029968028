.Square{
    background-color: white;
    border: 1px solid #999;
    height: 40px;
    width: 40px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 1px 3px;
}

.Black-Square{
    background-color: #282c34;
}

.Square-selected{
    background-color: #007bff;
}

.Square-Diag-Line
{
    position: relative;
    z-index: 1;
    left: -11px;
    top: -11px;
    width: 54px;
    height: 1px;
    background-color: #000;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari and Chrome */
}

.Square-Number{
  font-size: 10px;
}